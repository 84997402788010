import React, { useEffect, useState } from "react";
import { apiConstants } from "../../utils/constants/apiConstants";
import { Controller, useController } from "react-hook-form";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import useStore from "../../zustandstore";
import { getAvailableTiming } from "../../api";
import {
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import { convertDateToUTC, convertLocal } from "../../utils/globalFunctions";
import { showToast } from "../../utils";
import { makeGetCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import { currentDate } from "../../utils/dateUtils";
import { get } from "http";

const ScheduleDate = (props) => {
  const {
    control,
    name,
    errors,
    setValue, //??
    focusedInput, //??
    setFocusedInput,
    setDateSelected,
    setTimingError,
    getValues,
    setSelectedIndex,
    setTimingApi,
  } = props;

  //fetching Data from store

  const { timezone_id, location_id } = useStore((state: any) => state);

  const stylistUniqueCode = useStore((state: any) => state.stylistUniqueCode);
  const bookingDuration = useStore((state: any) => state.bookingDuration);
  const tz = useStore((state: any) => state.tz);
  const currentTime = useStore((state: any) => state.currentTime);
  const selectedServices = useStore((state: any) => state.selectedServices);
  const [localSelectedDate, setLocalSelectedDate] = useState<any>(null);
  const setStoreTiming = useStore((state: any) => state.setTiming);
  const timing = useStore((state: any) => state.timing);
  const setStoreSelectedDate = useStore((state: any) => state.setSelectedDate);
  const setBookingDuration = useStore((state: any) => state.setBookingDuration);
  const stylistLocationData = useStore(
    (state: any) => state.stylistLocationData
  );
  const [totalDuration, setTotalDuration] = useState<any>(null);
  const [fullDays, setFullDays] = useState<any>(null);
  const [unFitDays, setUnfitDays] = useState<any>(null);

  //++++++++CONTROLLER+++++
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    // errors,
  });

  const { rescheduleBookingID, reschedule } = useStore((state: any) => state);

  useEffect(() => {
    if (localSelectedDate) {
      handleGetAvailableTiming();
    }
  }, [localSelectedDate]);

  useEffect(() => {
    handleGetMonthlyFullDays(moment());
  }, []);

  useEffect(() => {
    if (fullDays) {
      getUnfitDates(fullDays);
    }
  }, [fullDays]);

  const handleBookingDuration = () => {
    const totalDuration = selectedServices
      .map((item) => item.duration)
      .reduce((acc, curr) => acc + curr);

    setBookingDuration("00 : 00  AM", totalDuration);
  };

  //+++Get Available Timing
  const handleGetAvailableTiming = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const currentTime = moment().format("HH:mm");
    const booking_duration = bookingDuration.duration;


    if (getValues(apiConstants.location) === "mbl_booking") {
      setValue(apiConstants.timezone, timezone_id);
    }

    let local_datetime = moment(`${localSelectedDate?.format("YYYY-MM-DD")} ${currentTime}`).tz(tz)

    let gettimingparameters = {
      tz: tz,
      selected_dt: local_datetime.format('YYYY-MM-DD HH:mm'),
      booking_duration: booking_duration,
      ...(reschedule && {
        booking_to_update: rescheduleBookingID,
        // tz: getValues(apiConstants.timezone),
      }),
    };


    if (getValues(apiConstants.location) != "mbl_booking") {
      gettimingparameters["location_id"] = location_id;
    }

    getAvailableTiming(gettimingparameters).then((res) => {
      if (res?.status?.code === 200) {
        setStoreTiming(res?.data);
        setTimingError(false);
        // return;
      } else if (res?.status?.code === 403) {
        setTimingError(true);
        // showToast(res?.status?.message, "error");
      }

      setTimingApi(res);
    });
  };

  //+++Handle Date Change+++
  const handleDateChange = (date) => {
    handleBookingDuration();

    setSelectedIndex(null);
    setLocalSelectedDate(date);
    setStoreSelectedDate(
      date?.format("YYYY-MM-DD"),
      tz,
      currentTime,
      location_id
    );
    setDateSelected(true);
    onChange(date);
  };
  //+++Day Blocking functionality++++

  const handleGetMonthlyFullDays = async (newMonth) => {
    const monthlyParams = {
      stylistUniqueCode: getURLstylistCode(),
      monthYear: newMonth.format('MM-YYYY'),
      locationId: location_id,
    };

    const servicesDuration = selectedServices
      .map((item) => item.duration)
      .reduce((acc, curr) => acc + curr);

    setTotalDuration(servicesDuration);

    try {
      const response = await makeGetCall({
        url: endpoints.get_full_days,
        params: monthlyParams,
      });

      const { data } = response;
      setFullDays(data);

    } catch (error) {
      showToast("Error", error.message);
    }
  };

  let workdays;
  if (getValues(apiConstants.location) != "mbl_booking") {
    workdays = stylistLocationData?.results?.filter(
      (item) => item.id === location_id
    )[0].working_hours;
  } else {
    workdays = stylistLocationData?.results[0]?.working_hours;
  }
  
  const temp = workdays?.filter((item) => item.is_active === false);
  let dayOff: number[] = []; // Stores number array where 0 is Monday and 6 is Sunday
  temp?.forEach((item) => {
    dayOff.push((item.weekday + 1) % 7);
  });

  const getUnfitDates = (fullDays) => {
    let unfitDates: any[] = [];
    fullDays?.forEach((item) => {
      if (moment.duration(totalDuration, 'minutes').asSeconds() > item[1]) {
        unfitDates.push(moment(item[0]).format('YYYY-MM-DD'));
      }
    });
    setUnfitDays(unfitDates);
  };

  const isDayBlocked = (day) => {
    return dayOff.includes(day.days()) || unFitDays?.includes(day.format('YYYY-MM-DD'));
  };

  const isOutsideRange = (day) => day.year() !== moment().year();

  //+++++++++RETURN+++++++
  return (
    <div>
      {errors?.[apiConstants.calander] ? (
        <p className="text-danger">{errors[apiConstants.calander]?.message}</p>
      ) : null}

      <Controller
        control={control}
        name={name}
        // errors={errors}
        render={() => {
          return (
            <>
              <SingleDatePicker
                id="my-datepicker-id"
                date={value}
                onDateChange={(date) => handleDateChange(date)}
                onNextMonthClick={handleGetMonthlyFullDays}
                onPrevMonthClick={handleGetMonthlyFullDays}
                focused={true}
                onFocusChange={(input) => setFocusedInput(input.focused)}
                numberOfMonths={1}
                initialVisibleMonth={() => value || moment()}
                isDayBlocked={isDayBlocked}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default ScheduleDate;
