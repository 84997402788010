import React, { useEffect, useState } from "react";
import Button from "../../global/ui/Button";
import { ROUTES_CONSTANTS } from "../../utils/constants/routesString";
import { useNavigate } from "react-router-dom";
import { apiConstants } from "../../utils/constants/apiConstants";

import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import { countryList } from "./customCountries";

import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { setLocalStorage, showToast } from "../../utils";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

import "react-toastify/dist/ReactToastify.css";
import { makePostCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";
import { images } from "../../utils/constants/assets";

interface SignUpProps {
  // handleNext?: () => void;
  handleNext?: any;
  control?: any;
  errors?: any;
  watch?: any;
  setValue?: any;
  getValues?: any;
  handlePrevious?: () => void;
  isValid?: any;
}

export default function SignUp(props: SignUpProps) {
  const {
    control,
    errors,
    watch,
    handleNext,
    handlePrevious,
    isValid,
    setValue,
    getValues,
  } = props;
  const navigate = useNavigate();

  const [checkPhoneNumber, setCheckPhoneNumber] = useState();

  const [activeIndex, setActiveIndex] = useState(0);
  const [countryCode, setCountryCode] = React.useState("us");

  async function Mobilecheckapi() {
    const phone_check = {
      mobile_number: `${countryList[activeIndex].dial_code}${getValues(
        apiConstants.mobile_number
      ).replace(/\s/g, "")}`,
    };

    try {
      const apiResponse = await makePostCall({
        url: endpoints.check_number,
        apiPayload: phone_check,
      });

      const data = await apiResponse;

      if (data.status.code === 200) {
        setValue(apiConstants.country_code, countryList[activeIndex].dial_code);
        handleNext();

        if (data?.data?.is_registered_online_consumer) {
          setLocalStorage(
            "is_registered_online_consumer",
            data?.data?.is_registered_online_consumer
          );
        } else {
          setLocalStorage(
            "is_registered_online_consumer1",
            data?.data?.is_registered_online_consumer
          );
        }
      } else {
        showToast(data.status.message, "error");
      }
      setCheckPhoneNumber(data);
    } catch (err) {}
  }

  window.onload = function () {
    window.localStorage.clear();
  };

  const handleSubmit = () => {
    const phoneNumber = parsePhoneNumber(
      getValues(apiConstants.mobile_number),
      countryList[activeIndex].code
    );

    const isPhonePossible =
      isPossiblePhoneNumber(
        getValues(apiConstants.mobile_number),
        countryList[activeIndex].code
      ) === true;

    const isValidPhone =
      isValidPhoneNumber(
        countryList[activeIndex].dial_code +
          getValues(apiConstants.mobile_number),
        countryList[activeIndex].code
      ) === true;

    if (phoneNumber && isValidPhone && isPhonePossible) {
      // if (!phoneNumber.isValid()) {
      //   showToast(STRING_CONSTANTS.invalid_number_entered, "error");
      // } else {

      setValue("flagIndex", activeIndex);
      Mobilecheckapi();
      // }
    } else {
      showToast(STRING_CONSTANTS.invalid_number_entered, "error");
    }
  };

  const mainstylistLocationData = useStore(
    (state: any) => state.stylistLocationData
  );

  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    if (props.errors) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [props.errors]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (getValues("flagIndex")) {
      setActiveIndex(getValues("flagIndex"));
    }
  });

  useEffect(() => {
    if (activeIndex != null) {
      const siblings = document.querySelectorAll(".your-class-selector");
      siblings.forEach((sibling) => {
        if (sibling != siblings[activeIndex]) {
          sibling.classList.remove("active");
        }
      });
    }
  }, [activeIndex]);

  function handleClick(index, name) {
    setActiveIndex(index);
    setCountryCode(name.toLowerCase());
    setIsOpen(!isOpen);
    setValue("flagIndex", index);
  }

  // const [isMobileValid, setIsMobileValid] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");
  const [mobileMask, setMobileMask] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  // useEffect(() => {

  // }, [mobileNumber]);

  const handlePhoneMasking = (mobileNumber) => {
    if (mobileNumber.replace(/\s+/g, "").length < 10) {
      setMobileMask("999 999 999 999");
    } else if (mobileNumber.length > 11) {
      setMobileMask(`9999 999 999 999 999`);
    }
  };

  return (
    <div
      className={
        window.location.pathname === ROUTES_CONSTANTS.signup
          ? "booking-wrapper"
          : ""
      }
    >
      <div className="step_4 sign_up_screen">
        <div className={`country-list-outer ${isOpen ? "open" : ""}`}>
          <div className="country-list">
            <span className="heading">Select Country Code</span>
            {countryList.map((i, index) => {
              return (
                <div
                  key={index}
                  className={`your-class-selector ${
                    index === activeIndex ? "active" : ""
                  }`}
                  onClick={() => handleClick(index, i.code)}
                >
                  <span className="country_icon">{i.flag}</span>
                  <p className="country_name">{i.name} </p>
                  <span className="country_code">{` \u00A0 (${i.dial_code})`}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="upper-wrapper">
          <div className="back-logo">
            {window.location.href.includes("signin") ? (
              <div
                className="go-back"
                onClick={() => {
                  navigate(-1);
                  // setStepNo(2);
                }}
              >
                <img src={images.go_back} alt="" />
              </div>
            ) : (
              <div
                className="go-back"
                onClick={() => {
                  window.location.pathname === ROUTES_CONSTANTS.signup
                    ? navigate(-1)
                    : handlePrevious?.();
                }}
              >
                <img src={images.go_back} alt="" />
              </div>
            )}
          </div>
          <h1>{STRING_CONSTANTS.new_here}</h1>
          <p>
            Login or sign-up to book with{" "}
            {mainstylistLocationData?.stylist_info?.full_name} by entering your
            mobile number below.
          </p>
          <div className="enter-number form-field">
            <div
              className={
                errors?.[apiConstants.mobile_number]
                  ? "input_box input_error"
                  : "input_box"
              }
            >
              <button className="country-icon-btn" onClick={toggleMenu}>
                <div className="country-icon">
                  {countryList[activeIndex].flag}
                </div>
              </button>

              <div className="mbl-field">
                <div className="prefix-code">
                  {countryList[activeIndex].dial_code}
                </div>
                <div>
                  <label htmlFor="">Mobile Number</label>

                  <InputMask
                    type="tel"
                    value={getValues(apiConstants.mobile_number)}
                    mask={mobileMask}
                    // maskPlaceholder={null}
                    maskChar={null}
                    onChange={(e: any) => {
                      handlePhoneMasking(e.target.value);
                      setValue(apiConstants.mobile_number, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PhoneInput
          // country={"us"}
          country={countryCode}
          defaultMask="+61 9999 999 999"
          value={mobileNo}
          onChange={(mobileNo) => setMobileNo(mobileNo)}
          autoFormat
        /> */}
        <div className="bottom-wrapper">
          <Button
            className={hasError ? "error continue-btn" : "continue-btn"}
            // disabled={!isValid}
            disabled={getValues(apiConstants.mobile_number) ? false : true}
            type="submit"
            btnTxt={"Continue"}
            isLoading={false}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
