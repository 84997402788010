import React, { useEffect, useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import {
  makeDeleteCall,
  makeGetCall,
  makePostCall,
} from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import { time } from "console";
import useStore from "../../zustandstore";
import { useNavigate } from "react-router-dom";
import {
  convertDateToUTC,
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { apiConstants } from "../../utils/constants/apiConstants";
import Button from "../../global/ui/Button";
import { showToast } from "../../utils";
import moment from "moment";

type StripePropType = {
  serviceIdArray?: any;
  totalAmount?: any;
  stylistLocationData?: any;
  selectedDate?: any;
  handleNext?: any;
  getValues?: any;
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          fontWeight: 700,
          color: "#2F2F2F",
          // letterSpacing: "0.025em",
          fontFamily: "sans-serif",
          "::placeholder": {
            color: "grey",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );
  return options;
};

const SplitForm = (props) => {
  const {
    serviceIdArray,
    totalAmount,
    locationData,
    handleNext,
    remainingAmount,
    depositAmount,
    cancelCharge,
    start_dt,
    end_dt,
    getValues,
    stylistLocationData,
    isCancellation,
    isDeposit,
  } = props;
  const [paymentRequest, setPaymentRequest] = useState<any>(null); //APPLE PAY GOOGLE PAY
  const [savedCardList, setSavedCardList] = useState([]);
  const [isDelete, setIsDelete] = useState(true);
  const [deleteCardId, setDeleteCard] = useState("");
  const {
    selectedServices,
    setBookingAPIData,
    stylistUniqueCode,
    rebookLocation,
  } = useStore((state: any) => state);
  const stylist_unique_code = { stylist_unique_code: stylistUniqueCode };
  //+++Formatting Date Time before sending it to Booking API
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(0); //0: Error 1: Success 2: Delete
  const [selectedCard, setSelectedCard] = useState("");
  const [showNewCard, setShowNewCard] = useState(true);
  const [note, setNote] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isReBook, setReBook] = useState(false);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [rebookID, setReBookID] = useState<any>(null);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.href.includes("rebookpayment")) {
      setReBook(true);
    }
  }, []);

  async function cardsApi() {
    try {
      const apiResponse = await makeGetCall({
        url: endpoints.payment_methods,
        params: stylist_unique_code,
      });
      const res = await apiResponse;

      if (res?.data) {
        setSavedCardList(res?.data || []);
      }

      showToast(res?.error?.message, "error");
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    cardsApi();
  }, []);

  const stripe: any = useStripe();
  const elements: any = useElements();
  const options = useOptions();

  //+++++++++++++++APPLE PAY/GOOGLE PAY++++++++++++++

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe?.paymentRequest({
      currency: "aud",
      country: "AU",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: "Demo",
        // amount: totalAmount * 100,
        amount: Math.trunc(depositAmount*100),
      },
    });
    pr.canMakePayment()
      .then((res) => {
        if (res) {
          //Show Button ++
          setPaymentRequest(pr);
        }
      })
      .catch((err) => console.log(err));
    // let paymentPayload = ((totalAmount * 100).toString())
    pr.on("paymentmethod", async (ev) => {
      let clientSecret: any;
      let bookingAPIPayload: any = {
        services: JSON.stringify(serviceIdArray),
        // start_dt: convertDateToUTC(start_dt),
        // end_dt: convertDateToUTC(start_dt),
        start_dt: start_dt,
        end_dt: end_dt,
        [apiConstants.address]: locationData?.[apiConstants.address],
        [apiConstants.country]: locationData?.[apiConstants.country],
        [apiConstants.country_code]: locationData?.[apiConstants.country_code],
        [apiConstants.location]: locationData?.[apiConstants.location],
        [apiConstants.city]: locationData?.[apiConstants.city],
        [apiConstants.zip_code]: locationData?.[apiConstants.zip_code],
        [apiConstants.state]: locationData?.[apiConstants.state],
        [apiConstants.state_code]: locationData?.[apiConstants.state_code],
        [apiConstants.location_image]:
          locationData?.[apiConstants.location_image],
      };

      if (
        isCancellation &&
        stylistLocationData?.stylist_info?.cancellation_charge_amount > "0"
      ) {
        bookingAPIPayload[apiConstants.cancellation_charges] = parseFloat(
          Number(cancelCharge)?.toFixed(2)
        );
      }

      if (
        isDeposit &&
        stylistLocationData?.stylist_info?.online_deposit_amount > "0"
      ) {
        bookingAPIPayload[apiConstants.deposited_amount] = depositAmount;
      }
      if (!isReBook) {
        if (getValues(apiConstants.location) !== "mobile_booking") {
          bookingAPIPayload["location_selected"] = locationData?.id;
          bookingAPIPayload["timezone"] = locationData?.timezone;
        }
      } else {
        bookingAPIPayload["timezone"] = rebookLocation?.timezone;
      }

      if (isReBook) {
        bookingAPIPayload = {
          ...bookingAPIPayload,
          [apiConstants.address]: rebookLocation?.address,
          [apiConstants.city]: rebookLocation?.[apiConstants.city],
          [apiConstants.state]: rebookLocation?.[apiConstants.state],
          [apiConstants.state_code]: rebookLocation?.[apiConstants.state_code],
          [apiConstants.zip_code]: rebookLocation?.[apiConstants.zip_code],
          [apiConstants.country]: rebookLocation?.[apiConstants.country],
          [apiConstants.country_code]:
            rebookLocation?.[apiConstants.country_code],
          [apiConstants.location]: rebookLocation?.[apiConstants.location],
          [apiConstants.location_image]:
            rebookLocation?.[apiConstants.location_image],
        };
      }

      if (rebookLocation?.location_selected != null) {
        bookingAPIPayload["location_selected"] =
          rebookLocation?.location_selected;
      }

      if (note) {
        bookingAPIPayload = { ...bookingAPIPayload, note };
      }

      makePostCall({
        url: `${
          endpoints.API_BASE_URL
        }stripe-terminal/get-payment-intent/?${getURLwithstylistCode()}&get_client_secret=true`,
        apiPayload: { amount: depositAmount },
      }).then((res) => {
        console.log(res, "+++++TEST");
        clientSecret = res?.data.payment_intent_id;

        async function tempFunc() {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );
          bookingAPIPayload = {
            ...bookingAPIPayload,
            consumer_payment_method_used: paymentIntent?.payment_method,
          };
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete("fail");
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete("success");
            if (paymentIntent.status === "succeeded") {
              makePostCall({
                url: `${
                  endpoints.API_BASE_URL
                }booking/?${getURLwithstylistCode()}&is_paid_through_wallet=true`,
                apiPayload: bookingAPIPayload,
                xTimezone: tz,
              }).then((result) => {
                console.log(result, "result");

                setShowPopup(!showPopup);
                if (isReBook) {
                  if (result?.status?.code === 200) {
                    setReBookID(result?.data.id);
                    setLoading(!isLoading);
                  } else {
                    showToast(result?.status?.message, "error");
                    // setLoading(isLoading);
                  }
                }

                if (result.error) {
                  console.log("Unsucessfull", result?.error.message);
                } else if (result?.status?.code === 200) {
                  setIsSuccess(1);
                  setBookingAPIData(result?.data);
                  setLoading(!isLoading);
                } else {
                  setLoading(false);
                  setShowPopup(false);
                  showToast(result?.status?.message, "error");
                }
              });
            }
          }
        } // tempfunction end
        tempFunc();
      });
    });
  }, [stripe, elements]);
  //++++++++++++++++++++++++++++++++++++++++++++++

  const [formComplete, setFormComplete] = useState({
    card_number: false,
    card_cvv: false,
    card_expiry: false,
  });

  const handleCardNumberChange = (event) => {
    // setFormComplete(event.complete);
    setIsCardComplete(event.complete);
    setFormComplete({ ...formComplete, card_number: event.complete });
  };

  const handleCardExpiryChange = (event) => {
    // setFormComplete(event.complete && formComplete);
    setFormComplete({ ...formComplete, card_cvv: event.complete });
  };

  const handleCardCvcChange = (event) => {
    // setFormComplete(event.complete && formComplete);
    setFormComplete({ ...formComplete, card_expiry: event.complete });
  };

  // console.log(formComplete, "form");

  const handleSubmit = async () => {
    setLoading(!isLoading);

    // return;

    let bookingAPIPayload: any = {
      services: JSON.stringify(serviceIdArray),
      start_dt,
      end_dt,
      [apiConstants.address]: locationData?.[apiConstants.address],
      [apiConstants.country]: locationData?.[apiConstants.country],
      [apiConstants.country_code]: locationData?.[apiConstants.country_code],
      [apiConstants.location]: locationData?.[apiConstants.location],
      [apiConstants.city]: locationData?.[apiConstants.city],
      [apiConstants.zip_code]: locationData?.[apiConstants.zip_code],
      [apiConstants.state]: locationData?.[apiConstants.state],
      [apiConstants.state_code]: locationData?.[apiConstants.state_code],

      [apiConstants.location_image]:
        locationData?.[apiConstants.location_image],
    };

    // let timeZonePayload: any = {
    //   xTimezone: locationData?.timezone,

    // };

    if (
      isCancellation &&
      stylistLocationData?.stylist_info?.cancellation_charge_amount > "0"
    ) {
      bookingAPIPayload[apiConstants.cancellation_charges] = parseFloat(
        Number(cancelCharge)?.toFixed(2)
      );
    }

    if (
      isDeposit &&
      stylistLocationData?.stylist_info?.online_deposit_amount > "0"
    ) {
      bookingAPIPayload[apiConstants.deposited_amount] = depositAmount;
    }

    if (!isReBook) {
      if (getValues(apiConstants.location) !== "mobile_booking") {
        bookingAPIPayload["location_selected"] = locationData?.id;
        bookingAPIPayload["timezone"] = locationData?.timezone;
      }
    } else {
      bookingAPIPayload["timezone"] = rebookLocation?.timezone;
    }

    if (isReBook) {
      bookingAPIPayload = {
        ...bookingAPIPayload,
        [apiConstants.address]: rebookLocation?.address,
        [apiConstants.city]: rebookLocation?.[apiConstants.city],
        [apiConstants.state]: rebookLocation?.[apiConstants.state],
        [apiConstants.state_code]: rebookLocation?.[apiConstants.state_code],
        [apiConstants.zip_code]: rebookLocation?.[apiConstants.zip_code],
        [apiConstants.country]: rebookLocation?.[apiConstants.country],
        [apiConstants.country_code]:
          rebookLocation?.[apiConstants.country_code],
        [apiConstants.location]: rebookLocation?.[apiConstants.location],
        [apiConstants.location_image]:
          rebookLocation?.[apiConstants.location_image],
      };
      if (rebookLocation?.location_selected != null) {
        bookingAPIPayload["location_selected"] =
          rebookLocation?.location_selected;
      }
    }

    if (note) {
      bookingAPIPayload = { ...bookingAPIPayload, note };
    }

    //Radio button of saved card is selected
    if (selectedCard) {
      let tempLast4;
      savedCardList.forEach((card) => {
        if (card.id === selectedCard) tempLast4 = card.last4;
      });
      bookingAPIPayload = {
        ...bookingAPIPayload,
        consumer_payment_method_last4_digit: tempLast4,
        consumer_payment_method_used: selectedCard,
      };

      try {
        makePostCall({
          url: `${endpoints.API_BASE_URL}booking/?${getURLwithstylistCode()}`,
          apiPayload: bookingAPIPayload,
          xTimezone: tz,
        })
          .then(function (result) {
            if (isReBook) {
              if (result?.status?.code === 200) {
                setReBookID(result?.data.id);
                setLoading(!isLoading);
                setShowPopup(!showPopup);
              } else {
                showToast(result?.status?.message, "error");
                // setLoading(isLoading);
              }
            }

            if (result.error) {
              // console.log("Unsucessfull", result?.error.message);
            } else if (result?.status?.code === 200) {
              setIsSuccess(1);
              setBookingAPIData(result?.data);
              setLoading(!isLoading);

              setShowPopup(!showPopup);
            } else {
              setLoading(isLoading);

              setShowPopup(showPopup);
              showToast(result?.status?.message, "error");
            }
          })
          .catch((err) => {
            setLoading(!isLoading);
            setIsSuccess(0);
          });
      } catch (err) {
        setIsSuccess(0);
      }
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      await stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
        })
        .then(function (result) {
          if (result.error) {
            console.log("Unsucessfull", result?.error.message);
          } else {
            setIsSuccess(1);
          }
          bookingAPIPayload = {
            ...bookingAPIPayload,
            consumer_payment_method_used: result?.paymentMethod?.id,
          };
          makePostCall({
            url: `${endpoints.API_BASE_URL}booking/?${getURLwithstylistCode()}`,
            apiPayload: bookingAPIPayload,
            xTimezone: tz,
          }).then((res) => {
            // cardsApi();

            if (res?.status?.code === 200) {
              setLoading(!isLoading);
              setShowPopup(!showPopup);
              setBookingAPIData(res?.data);
              setReBookID(res?.data.id);
            } else {
              setLoading(false);
              setShowPopup(false);
              showToast(res?.status?.message, "error");
            }
          });
        });
    }
    setNote("");
  };
  const handlePopupClose = () => {
    setShowPopup(false);
    setLoading(false);
    if (isSuccess === 1) {
      if (!isReBook) {
        handleNext();
      } else {
        // setBookingDetails();
        navigate(
          `/${getURLstylistCode()}/booking_details/?appoitmentrebooked&id=${rebookID}`
        );
        //   navigate(
        //     `/booking_details/?${getURLstylistCode()}&id=${rebookID}`
        //   );
      }
    }
  };

  const [approxTotalPopUp, setApproxTotalPopUp] = useState(false);

  const approxTotal = () => {
    setApproxTotalPopUp(!approxTotalPopUp);
  };

  //Render Tooltip function
  const renderTooltip = selectedServices.map((item) => {
    return (
      <div className="approx-inner">
        <div className="name">{item.name}</div>
        <div className="amount"> $ {item.amount}</div>
      </div>
    );
  });

  //Handling Cards
  const handleCardSelection = (e) => {
    setSelectedCard(e.target.value);
    setShowNewCard(false);
  };

  const handleNewCardInput = (e) => {
    setSelectedCard("");
    setShowNewCard(true);

    setFormComplete({
      ...formComplete,
      card_number: false,
      card_cvv: false,
      card_expiry: false,
    });
  };

  //HandleCardDeleteConfirm
  const handleDeleteConfirm = () => {
    setIsDelete(!isDelete);
    setShowPopup(!showPopup);
    DeleteCard();
    setIsSuccess(0);
  };

  //Handle Delete Card
  const handleDeleteCard = async (id) => {
    setIsSuccess(2);
    setShowPopup(!showPopup);

    setDeleteCard(id);
  };

  // if (CardNumberElement.propTypes?.className=="is-empty") {
  //   console.log("hey");
  // }

  const DeleteCard = async () => {
    if (isDelete) {
      try {
        await makeDeleteCall({
          url: endpoints.payment_methods,
          apiPayload: { source_id: deleteCardId },
        });
        cardsApi();
      } catch (err) {
        console.log(err);
      }
    }
  };

  //Render Saved Card List
  const renderSavedCards = () => {
    const renderCardList = savedCardList.map((card) => {
      return (
        <div key={card.id} className="custom_checkbox">
          <div>
            <input
              type="radio"
              id={card.id}
              name="card"
              value={card.id}
              checked={selectedCard === card.id}
              onChange={handleCardSelection}
            />

            <label htmlFor={card.id} className="checkmark">
              **** {card.last4}
            </label>
          </div>
          <button
            type="button"
            onClick={() => handleDeleteCard(card.id)}
            className="delete-card"
          >
            <img src={images.del_card_icon} alt="" />
          </button>
        </div>
      );
    });
    return renderCardList;
  };
  //++++++s

  return (
    <div>
      <form>
        {/* <PaymentElement /> */}
        <div className="select-card ">
          {savedCardList && renderSavedCards()}
          <div className="custom_checkbox">
            <input
              type="radio"
              id="new-card"
              name="card"
              value="new"
              checked={selectedCard === ""}
              onChange={handleNewCardInput}
            />
            <label htmlFor="new-card" className="checkmark">
              New Card:
            </label>
          </div>
        </div>
        {showNewCard && (
          <div>
            <label>
              Card number
              <CardNumberElement
                options={options}
                onChange={handleCardNumberChange}
              />
            </label>
            <label>
              CVC
              <CardCvcElement
                options={options}
                onChange={handleCardExpiryChange}
              />
            </label>
            <label>
              Expiration date
              <CardExpiryElement
                options={options}
                onChange={handleCardCvcChange}
              />
            </label>
          </div>
        )}
        <label>
          Additional info
          <textarea
            value={note}
            placeholder="Tell us more"
            onChange={(e) => setNote(e.target.value)}
            required
          />
        </label>
        <div className="bottom-wrapper">
          <div className="all-totals">
            <div className="approx-total">
              {approxTotalPopUp && (
                <div className="approx">
                  <div style={{ width: "100%" }}>{renderTooltip}</div>
                </div>
              )}

              <div>
                <img
                  src="/assets/images/question.svg"
                  alt=""
                  onClick={approxTotal}
                />
                Approximate total
              </div>
              <div>${Number(totalAmount).toFixed(2)}</div>
            </div>

            {isDeposit &&
              stylistLocationData?.stylist_info?.online_deposit_amount >
                "0" && (
                <div className="total-dipst">
                  <div>
                    <img
                      src="/assets/images/question.svg"
                      alt=""
                      style={{ visibility: " hidden" }}
                    />
                    Total Deposit
                  </div>
                  <div>${Number(depositAmount).toFixed(2)}</div>
                </div>
              )}

            {/* {isCancellation &&
              stylistLocationData?.stylist_info?.cancellation_charge_amount >
                "0" && (
                <div className="total-dipst">
                  <div>
                    <img
                      src="/assets/images/question.svg"
                      alt=""
                      style={{ visibility: " hidden" }}
                    />
                    Cancellation charges
                  </div>
                  <div>${Number(cancelCharge).toFixed(2)}</div>
                </div>
              )} */}
            {isDeposit &&
              stylistLocationData?.stylist_info?.online_deposit_amount >
                "0" && (
                <div className="remaining-amt">
                  <div>
                    <img
                      src="/assets/images/question.svg"
                      alt=""
                      style={{ visibility: " hidden" }}
                    />
                    {STRING_CONSTANTS.balance_due}
                  </div>
                  <div>${remainingAmount}</div>
                </div>
              )}
          </div>

          {isDeposit &&
            stylistLocationData?.stylist_info?.online_deposit_amount > "0" && (
              <div>
                {paymentRequest && (
                  <PaymentRequestButtonElement options={{ paymentRequest }} />
                )}
              </div>
            )}
          <Button
            type="button"
            btnTxt={"Pay & Confirm"}
            className="error continue-btn Pay-btn"
            onClick={() => handleSubmit()}
            disabled={
              (!formComplete.card_number ||
                !formComplete.card_cvv ||
                !formComplete.card_expiry) &&
              showNewCard && !isCardComplete
            }
          />
        </div>
      </form>
      {isLoading && (
        <div className="pay-popup-outer">
          <div className="overlay"></div>
          <div className="pay-popup">
            <div className="gif-img">
              <img src="/assets/images/loading.gif" alt="" />
            </div>
            Please wait, creating your booking.
          </div>
        </div>
      )}
      {showPopup ? (
        <div className="pay-popup-outer">
          <div className="overlay" onClick={handlePopupClose}></div>
          <div className="pay-popup">
            <div className="gif-img">
              {isSuccess === 1 ? (
                <img src="/assets/images/success-sign.gif" alt="" />
              ) : isSuccess === 0 ? (
                <img src="/assets/images/gifs/danger-sign.gif" alt="" />
              ) : (
                <img src="/assets/images/gifs/danger-sign.gif" alt="" />
              )}
            </div>
            <div className="heading">
              {isSuccess === 1
                ? STRING_CONSTANTS.booking_req
                : isSuccess === 0
                ? STRING_CONSTANTS.booking_err
                : STRING_CONSTANTS.are_sure}
            </div>
            <div className="subtitle">
              {isSuccess === 1
                ? STRING_CONSTANTS.thank_booking
                : isSuccess === 0
                ? STRING_CONSTANTS.please_correct
                : STRING_CONSTANTS.you_want_delete}
            </div>
            {isSuccess === 2 ? (
              <div>
                <div className="close-btn-delete">
                  <div
                    className="close-btn close-btn-1"
                    onClick={handleDeleteConfirm}
                  >
                    Yes
                  </div>
                  <div className="close-btn" onClick={handlePopupClose}>
                    No
                  </div>
                </div>
              </div>
            ) : (
              <div className="close-btn" onClick={handlePopupClose}>
                Close
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SplitForm;

